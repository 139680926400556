// globalState.js
import { useRef } from 'react';

const isUseCaseStoryOpenRef = { current: false };

export const setIsUseCaseStoryOpen = (value) => {
  isUseCaseStoryOpenRef.current = value;
};

export const getIsUseCaseStoryOpen = () => {
  return isUseCaseStoryOpenRef.current;
};
